<template>
  <div style="margin-top: 1rem">
    <Panel :header="header" :toggleable="true">
      <template #icons>
        <a href="#" @click="showAddOption" class="addItem">Add Option</a>
      </template>

      <DataTable
        :value="items"
        @rowReorder="onRowReorder"
        responsiveLayout="scroll"
      >
        <Column
          :rowReorder="true"
          headerStyle="width: 3rem"
          :reorderableColumn="false"
        />
        <Column
          v-for="col of columns"
          :field="col.field"
          :header="col.header"
          :key="col.field"
        >
          <template #body="{ field, data }">
            <InputText
              type="text"
              v-model="data[field]"
              @change="onRowUpdate(data, field)"
              style="width: 100%"
            />
          </template>
        </Column>

        <Column :exportable="false" style="width: 1rem" columnKey="more">
          <template #body="{ index }">
            <div>
              <Button
                @click="toggle(index, $event)"
                class="p-button-rounded p-button-text"
              >
                <img src="@/assets/icons/More.png" alt="more" />
              </Button>
              <OverlayPanel
                :ref="
                  (el) => {
                    ops[index] = el;
                  }
                "
              >
                <a href="#" @click="deleteItem">Delete</a>
              </OverlayPanel>
            </div>
          </template>
        </Column>
      </DataTable>
    </Panel>

    <Dialog header="Add Option" v-model:visible="display">
      <div>
        Name
        <InputText type="text" v-model="name" style="width: 100%" />
      </div>
      <div style="margin-top: 0.5rem">
        Note
        <InputText type="text" v-model="note" style="width: 100%" />
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="closeAddOption"
          class="p-button-text"
        />
        <Button label="OK" icon="pi pi-check" @click="addItem" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import OverlayPanel from "primevue/overlaypanel";
import Panel from "primevue/panel";

export default defineComponent({
  components: {
    Button,
    Column,
    DataTable,
    Dialog,
    OverlayPanel,
    InputText,
    Panel,
  },

  props: ["header", "items"],

  emits: ["addItem", "deleteItem", "onRowReorder", "onRowUpdate"],

  setup(props, { emit }) {
    const ops = ref([]);
    const display = ref(false);
    const name = ref();
    const note = ref();

    const rowIndex = ref();
    const toggle = (index, event) => {
      ops.value[index].toggle(event);
      rowIndex.value = index;
    };

    const columns = ref([
      { field: "name", header: "Name" },
      { field: "note", header: "Note" },
    ]);

    const showAddOption = () => {
      name.value = "";
      note.value = "";
      display.value = true;
    };

    const closeAddOption = () => {
      display.value = false;
    };

    const addItem = () => {
      display.value = false;

      emit("addItem", name.value, note.value);
    };

    const deleteItem = (event) => {
      event.preventDefault();

      ops.value[rowIndex.value].toggle(event);

      console.log(`Record: ${rowIndex.value}`);
      emit("deleteItem", rowIndex.value);
    };

    const onRowReorder = (event) => {
      const record = event.value[event.dropIndex];
      const previousIndex = event.dropIndex - 1;
      const previousRecord =
        previousIndex >= 0 ? event.value[previousIndex] : null;

      emit("onRowReorder", record, previousRecord);
    };

    const onRowUpdate = (data, field) => {
      emit("onRowUpdate", data, field);
    };

    return {
      columns,
      showAddOption,
      addItem,
      deleteItem,
      onRowReorder,
      onRowUpdate,

      toggle,
      ops,
      display,
      closeAddOption,

      name,
      note,
    };
  },
});
</script>

<style scoped>
.addItem {
  font-size: medium;
  font-weight: 500;
}
</style>
